<template>
  <layout-page>
    <template v-slot:toolbar-title>
      <v-toolbar-title>{{translate('Client Error Log')}}</v-toolbar-title>
    </template>
    <template v-slot:toolbar-items>
      <v-toolbar-items>
        <v-btn text @click="clearClientErrors">
          <v-icon left>fas fa-trash</v-icon>{{translate('Clear log')}}
        </v-btn>
        <v-dialog
          v-model="pasteDialog"
          >
          <template v-slot:activator="{on}">
            <v-btn text v-on="on">
              <v-icon left>fas fa-clipboard</v-icon>&nbsp;{{translate('Show as text')}}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              {{translate('Client Error Log')}}
              <v-spacer></v-spacer>
              <v-btn icon @click="pasteDialog = false">
                <v-icon>close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-textarea :readonly="true" :value="JSON.stringify(clientErrors, null, 2)"/>
            </v-card-text>
            <v-divider></v-divider>
          </v-card>
        </v-dialog>
      </v-toolbar-items>
    </template>

    <v-card>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">{{translate('Time')}}</th>
                <th class="text-left">{{translate('Error')}}</th>
                <th class="text-left">{{translate('Info')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in clientErrors" v-bind:key="i">
                <td><nobr>{{formatTime(item.time)}}</nobr></td>
                <td>
                  <div class="client-error-info">
                    {{item.error}}
                  </div>
                </td>
                <td>
                  <div class="client-error-info">
                    {{item.info}}
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </layout-page>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import dayjs from "dayjs"
import LayoutPage from '@/components/layout/layout-page.vue'
export default {
  data: () => ({
    pasteDialog: false
  }),
  components: {
    LayoutPage,
  },
  computed: {
    ...mapGetters(['translate', 'clientErrors'])
  },
  methods: {
    ...mapActions(['clearClientErrors']),
    formatTime (time) { return dayjs(time).format('YYYY-MM-DD HH:mm:ss') }
  }
}
</script>
<style scoped>
.client-error-info {
  max-height: 8em;
  overflow: auto;
}
</style>
